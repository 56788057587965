import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ga from 'react-ga4';
import ym, { YMInitializer } from 'react-yandex-metrika';
import { withRouter } from 'react-router-dom';
import { path } from 'ramda';
import { COMMON_GA, LANDING_GA } from '../constants/ga';

const sendPageView = ({ location: { pathname }, action }) => {
    const { googleMetrics, yandexMetrics } = path(['data', 'pageDescription'], action);

    if (googleMetrics || window.GOOGLE_METRICS) {
        ga.set({ page: pathname });
        ga.send({ hitType: 'pageview', page: pathname });
    }

    if (yandexMetrics) {
        ym('hit', pathname);
    }
};

const ymOptions = {
    clickmap: true,
    trackLinks: true,
    accurateTrackBounce: true,
    webvisor: true
};

class TrackPageView extends Component {
    static propTypes = {
        action: PropTypes.object.isRequired
    }

    componentDidMount() {
        const googleMetrics = path(['pageDescription', 'googleMetrics'], this.props.action.data);
        let googleTrackers = [];

        if (window.GOOGLE_METRICS) {
            googleTrackers.push({ trackingId: window.GOOGLE_METRICS, gaOptions: { name: COMMON_GA }});
        }

        if (googleMetrics) {
            googleTrackers.push({ trackingId: googleMetrics, gaOptions: { name: LANDING_GA }});
        }

        if (googleTrackers.length) {
            ga.initialize(googleTrackers);
        }

        sendPageView(this.props);
    }

    componentDidUpdate() {
        sendPageView(this.props);
    }

    render() {
        const yandexMetrics = path(['pageDescription', 'yandexMetrics'], this.props.action.data);

        return yandexMetrics ? (
            <YMInitializer
                accounts={[parseInt(yandexMetrics, 10)]}
                options={ymOptions}
                version="2"
            />
        ) : null;
    }
}

export default withRouter(TrackPageView);
